import React from 'react'
import Layout from '../../components/Layout'
import SEO from '../../components/seo'
import FAQ from '../../components/FAQ'
import { Link } from 'gatsby'
import styles from '../../components/layout.module.scss'
import GetStartedOrChat from '../../components/CTA/GetStartedOrChat'
import Teaser from '../../components/Teaser'

class Page extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <SEO
          title="How to Create an Great Podcast Graphic That Pops"
          description="You don't need to be a graphic designer to make your podcast look professional. Follow this guide to make legible and eye-catching cover art photos."
        />

        <article>
          <h1>How to Create an Great Podcast Graphic That Pops</h1>

          <p>
            If you think podcasts are anything short of an all-out American craze, it's time to think again. According
            to recent data, about <a href="https://discoverpods.com/podcast-trends-report-2019/">82.4% of people</a>{' '}
            spend 7 hours or more a week listening to their favorite podcasts online!
          </p>
          <p>
            As someone who's beginning to set up and air a podcast of your own, you're likely wondering how to reach
            this wide audience. How can you make your show appeal to people in a way that will compel them to listen?
          </p>
          <p>
            The answer lies at least in part in creating an awesome first impression with a well-designed podcast
            graphic. Read on to learn how you can engage your listeners visually before creating the auditory impression
            that you crave!
          </p>
          <h2>Keep Your Podcast Graphic Relevant</h2>
          <p>
            Keeping your graphic relevant to your show is critical to the success of your podcast. You want to give
            people a taste of what you're all about at a single glance. This will help to get people interested in what
            you have to offer. It also will ensure that you engage the most interested audience since those who are
            interested in the subject material will click on the relevant icon.
          </p>
          <p>
            Consider the type of podcast that you're airing. If you have a hobby podcast, you'll want an icon that
            somehow incorporates artwork specific to your hobby. For example, if you're going to be talking about a TV
            show like <em>The Office, </em>you'll want an icon featuring a 'World's Best Boss' mug or a beet. The bottom
            line is that you want people who are interested in the show to recognize the symbol and think it's
            interesting.
          </p>
          <p>
            If your podcast is about yourself or your business, consider a personal or business logo for your graphic. A
            photo of your face like Conan O'Brien uses will help form a person-to-person connection with listeners if
            your podcast is personal. Your brand name or logo will make your business memorable or recognizable.
          </p>
          <h2>Use Color Psychology</h2>
          <p>
            Another way to make your podcast as relevant as possible is to capture the tone of the show. Color
            psychology, or the scientifically-proven phenomenon of colors impacting mood, is a great way to do this. You
            can use colors that invoke the same emotions as your show (hopefully) does to set the tone for what's to
            come.
          </p>
          <p>
            If you're hosting an open mic poetry reading, for example, a dark blue background like{' '}
            <a href="https://unlatchedpodcast.com/">this awesome podcas</a>t uses is a great way to make people feel
            calm and at home when listening. Podcasts with a lot of amped-up comedy may prefer yellow or orange graphics
            since these colors are social and energetic.
          </p>
          <p>
            Another example of this is <em>Welcome to Night Vale</em>'s logo. Purple is the color of mystery and
            imagination, and their entire podcast tells mysterious and incredibly imaginative stories that are designed
            to entrance the reader with their intrigue and weirdness.
          </p>
          <h2>Use Professional-Quality Tools</h2>
          <p>
            Even if you're not a graphic designer, there are sites that will allow you to create a graphic for free.
            Adobe Spark is one of the most user-friendly platforms out there, and you can use various templates, fonts,
            and image resolutions to create the perfect logo for you.{' '}
            <a href="https://pixlr.com/editor/">Pixlr free photo editor</a> is also an online tool that you can look
            into if you want to edit pre-existing images together.
          </p>
          <p>
            If you don't mind paying a little bit for your tools, many graphic design amateurs have sung the praises of
            Wix in recent years. It's generally used for making websites, but there are tools on the site that you can
            use to make graphics. Of course, Photoshop is also a classic way to edit images, and you can resize them for
            uploading once you're done editing!
          </p>
          <h2>Size Your Graphic Appropriately</h2>
          <p>
            Once your graphic is well-designed and awesome looking, it's time to focus on uploading it to the platforms
            your listeners use. You need to make it{' '}
            <a href="https://echowave.io/how-to-post-audio-on-facebook/">work on Facebook</a> as well as Spotify,
            YouTube, Apple Podcasts, and your official website.
          </p>
          <p>
            A big part of this is sizing your graphic to fit onto each of these platforms. If you don't do an
            appropriate job of this, it either simply won't upload or it will end up grainy and unprofessional. Do your
            research as to what each platform that you're uploading to necessitates so that you won't encounter any
            problems in the future.
          </p>
          <p>
            Speaking of the future, you want to make your graphic timeless. This means creating a graphic size that will
            still be relevant in a few years. On Apple platforms alone, the size has greatly grown in the past couple of
            years. It used to be that you needed a minimum of 300 x 300 pixels. Now, though, you need a graphic that's
            1400 x 1400 pixels minimum.
          </p>
          <p>
            Avoid needing to redo your sizing by simply making your graphic higher-resolution than you necessarily need
            to. 3000 x 3000 is a good choice. The worst that will happen is that you'll have a higher-quality image than
            other podcasts, which isn't a downside at all.
          </p>
          <h2>Make Your Graphic Shareable</h2>
          <p>
            To get the word out about your budding podcast, you'll want to make your graphic easy to share. Make sure
            that the software you use makes it easy for viewers to download the image. They then will be able to post it
            on social media or send it to friends. Additionally, make sure that all social media posts that include your
            graphic are public and can be shared and retweeted!
          </p>
          <p>
            When you do this, make sure that the link to your latest episode is also posted along with the graphic. This
            makes it easy for people to find and tune into!
          </p>
          <h2>Get Cracking!</h2>
          <p>
            Now that you know how to create a memorable and engaging podcast graphic, it's time to get started!{' '}
            <a href="https://app.echowave.io/register">Create your free account</a> on our website to begin transforming
            your podcast on multiple platforms. Our experts are happy to help you retarget your audio content and share
            your podcast with listeners in a fun and engaging way.
          </p>
          <p>We look forward to hearing from you soon!</p>
        </article>
      </Layout>
    )
  }
}

export default Page
